<template>
    <div>
        <div style="width: 100%;">
            <div style="display: flex;justify-content: center">
                <span style="font-size: 20px;color: #444444;font-weight: bold;">填写自定义咨询订单</span>
            </div>
            <el-divider style="width: 100%"></el-divider>
            <div style="display: flex;justify-content: space-between">
                <div class="block" style="margin-left: 30px"><span style="color: red">*</span>自定义咨询标题</div>
                <div class="block" style="margin-right: 30px;">咨询方式：
                    <el-radio-group class="code" v-model="form.type">
                        <el-radio class="code hand op sc" :label="1">视频咨询</el-radio>
                        <el-radio class="code hand op sc" :label="2">在线函复</el-radio>
                        <el-radio class="code hand op sc" :label="3">线下服务</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <el-input v-model="form.title" style="width: 1140px;height: 38px;margin-left: 30px;margin-top: 5px;"></el-input>
            <div style="display: flex;justify-content: space-between;">
<!--                <div style="margin-left: 30px;">-->
<!--                    <div class="block" style="line-height: 40px">咨询类别</div>-->
<!--                    <el-cascader v-model="form.topicArr" :options="typeList" style="width: 554px;height: 54px;"></el-cascader>-->
<!--                </div>-->
                <div style="margin-right: 10px;margin-left: 30px;">
                    <div class="block" style="margin-top: 10px"><span style="color: red">*</span>咨询日期</div>
                    <el-date-picker
                        style="width: 193px;margin-top: 5px"
                        v-model="form.consultTime"
                        type="datetime"
                        placeholder="选择日期时间"
                        format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="timePickerOptions">
                    </el-date-picker>
                </div>
                <div style="margin-right: 10px;">
                    <div class="block" style="margin-top: 10px"><span style="color: red">*</span>咨询时长</div>
                    <el-input-number style="margin-top: 5px" v-model="form.consultDuration" :min="1" :max="24" ></el-input-number>
                </div>
                <div style="margin-right: 30px;">
                    <div class="block" style="margin-top: 10px"><span style="color: red">*</span>最终费用<span style="font-size: 12px;color: #999999;"> 单位(元)</span></div>
                    <el-input style="margin-top: 5px" v-model="form.consultCost" prefix-icon="el-icon-coin" placeholder="请输入协商好的报价" @input="checkValue($event)"></el-input>
                </div>
            </div>
            <div class="block" style="margin-left: 30px;margin-top: 10px"><span style="color: red">*</span>咨询内容</div>
            <el-input
                class="no-resize"
                style="width: 1140px;height: auto;margin:5px 30px;"
                type="textarea"
                :rows="5"
                placeholder="请输入内容"
                v-model="form.content">
            </el-input>
            <div style="display: flex;margin-top: 5px;">
                <el-button class="hand op sc" @click="addconsultCost" style="margin-left: 30px;" size="small" type="primary">点击上传</el-button>
                <div style="margin-left: 10px;margin-top: 15px;"><span>附件数量:(<span style="color: red">{{files.length}}</span>)</span></div>
            </div>
            <el-dialog :close-on-click-modal="false" :show-close=false :append-to-body="true" :visible.sync="consultCostList" width="398px">
                <el-tabs v-model="file_tag_name" type="border-card">
                    <el-tab-pane label="电脑上传" name="pc" >
                        <div class="dragger-file">
                            <el-upload  accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx" style="width:330px"  class="upload_url"  action="" drag multiple :http-request="upload_file" :show-file-list="false" >
                                <i class="el-icon-upload" ></i>
                                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                <div class="format">仅限pdf、doc、docx、xls、xlsx等文件格式</div>
                            </el-upload>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="手机上传" name="mobile">
                        <div style="padding:10px;color:#a6a6a6;line-height:20px;">
                            <p>使用微信“扫一扫”打开小程序</p><P>在 百工宝小程序 中向“文件助手”发送文件</P>
                        </div>
                        <div style="text-align:center;">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/other/ewm/xcx.jpg" style="width:150px;height:150px;">
                        </div>
                    </el-tab-pane>
                </el-tabs>
                <div style="overflow: hidden; height:20px;line-height:20px; padding: 0 16px; margin-bottom: 10px">
                    <div style="float:left;color:#acacac;">
                        附件:（{{files.length}}）<!-- /9 -->
                    </div>
                </div>
                <div style="overflow:hidden;">
                    <div class="video" v-for="(item,index) in files" :key="index" style="width:80px;height:100px;border-radius:3px; float:left; margin:5px; background-size:100%;position:relative;" :title="item.file_name">
                        <div class="background" style="width:100%;height:calc(100% - 20px);background-color: rgba(0,0,0,.3);">
                            <img :src="ossUrl+'img/file.png'" style="width:100%;height:100%;" />
                        </div>
                        <div style="height:20px;width:70px;margin: 0 5px;line-height: 20px;text-align: center;word-break:keep-all;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{item.name}}</div>
                        <div style="background:#ff7575;color:#FFF;text-align:center;width:20px;height:20px;line-height:20px;border-radius:10px; cursor:pointer;user-select: none;position:absolute;right:0;top:0;" @click="removeFile(index)">
                            <i class="el-icon-close"></i>
                        </div>
                    </div>
                </div>
                <div style="display: flex; justify-content: flex-end;">
                    <el-button style="width: 80px;border-radius: 5px; margin: 10px 20px" @click="cancelFiles">取消</el-button>
                    <el-button style="width: 80px;border-radius: 5px; background-color: #031F88; color: #fff; margin: 10px 16px 10px 0" @click="consultCostList = false">确定</el-button>
                </div>
            </el-dialog>
            <div style="margin-left: 30px;margin-top: 10px;" v-if="form.type === 1 || form.type === 2">
                <div class="block" >备注说明</div>
                <el-input v-model="form.remark" type="textarea" :rows="4" placeholder="请填写附件说明及备注情况" style="width: 1140px;height: 100px;margin-top: 5px;"></el-input>
            </div>
            <div style="display: flex;margin-top: 10px;" v-if="form.type === 3">
                <div style="margin-left: 30px;">
                    <div class="block" >咨询预约地点</div>
                    <el-input v-model="site" type="textarea" :rows="4" placeholder="请填写附件说明及备注情况" style="width: 554px;;height: 100px;margin-top: 5px;"></el-input>
                </div>
                <div style="margin-left: 30px;">
                    <div class="block" >备注说明</div>
                    <el-input v-model="form.remark" type="textarea" :rows="4" placeholder="请填写附件说明及备注情况" style="width: 554px;;height: 100px;margin-top: 5px;"></el-input>
                </div>
            </div>
            <div style="margin-left: 30px;margin-top: 10px;">
                <div class="block" ><span style="color: red">*</span>选择专家<span style="font-size: 12px;color: #999999;">（将订单一键发送给关注的专家，等待专家的回应即可生成有效订单）</span></div>
                <el-select v-model="selectList" @change="updateExpert" style="margin-top: 5px;" placeholder="请选择">
                    <el-option v-for="item in expertList_code" :key="item.id" :label="item.nickName" :value="item.uuid"></el-option>
                </el-select>
            </div>
            <div style="margin-left: 30px;margin-top: 10px;">
                <div class="block" >订单权限:
                    <el-radio-group class="code" v-model="form.isOpen">
                        <el-radio class="code hand op sc" :label="0">订单不对外公开</el-radio>
                        <el-radio class="code hand op sc" :label="1">订单对外公开</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div style="display: flex;justify-content: center;align-content: center">
                <div class="hand op sc" style="margin: 10px auto" @click="makeConsult">
                    <el-button style="background: #2970FF;color: #FFFFFF;">生成订单</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {

    components: {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
        is_login() {
            return this.$store.state.is_login
        }
    },
    props: {

    },
    watch: {

    },
    data() {
        var durationRange = (rule, value, callback) => {
            if (value < 1) {
                callback(new Error('最少咨询1小时'));
            } else if (value > 8) {
                callback(new Error('最多咨询8小时'));
            } else {
                callback()
            }
        };
        return {
            timePickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;//如果没有后面的-8.64e7就是不可以选择今天的
                }
            },
            selectList: [],

            // 咨询预约地点
            site:"",
            expertList_code:[],
            typeList:[],


            form: {
                expert: "",
                expertTopic: 0,
                title: '',
                content: '',
                type: 1,
                remark: '',
                consultTime: "",
                consultDuration: 1,
                consultCost: "",
                isOpen:0,
            },
            consultCostList:false,
            file_tag_name: 'pc',
            files:[],
            upload_qrcode:"https://baigongbao.oss-cn-beijing.aliyuncs.com/img/xcx_qrcode.jpg-80*80",

        }
    },
    created: function () {

    },

    mounted: function () {
        this.getExpertCollectionList();
        // this.getType();
    },
    methods: {
        updateExpert(value) {
            this.form.expert = value;
        },

        makeConsult() {
            var that = this;
            if (that.form.title == ""){
                this.utils.err('请填写自定义咨询标题')
                return
            }
            if (that.form.consultTime == ""){
                this.utils.err('请填写咨询日期')
                return
            }
            if (that.form.consultCost == ""){
                this.utils.err('请填写咨询最终费用')
                return
            }
            if (that.form.content == ""){
                this.utils.err('请填写咨询内容')
                return
            }
            if (that.selectList == ""){
                this.utils.err('请填写选择一位专家')
                return
            }

            var params = that.form;
            params.enclosure = JSON.stringify(that.files);
            that.newApi.makeConsult(params).then(res => {
                if (res.isSuccess == 1) {
                    that.utils.sus(res.data)
                    that.selectExpert = false;
                    that.selectList = [];
                    that.files = [];
                    that.form = {
                        expert: "",
                        expertTopic: "",
                        title: '',
                        content: '',
                        type: 1,
                        topicTitle: '',
                        consultCost: 0,
                        consultTime: "",
                        consultDuration: 1,
                        remark: '',
                        isOpen: 0,
                    };
                    that.$parent.$parent.relese()
                }
            })
        },
        getExpertCollectionList() {
            var that = this;
            var params = {};
            params.pageSize = 10;
            params.page = 1;

            this.newApi.getExpertCollectionList(params).then(res => {
                that.expertList_code = res.data;
            })
        },
        // getType() {
        //     this.newApi.getTypeList({
        //         lv: 3
        //     }).then(res => {
        //         this.typeList = res.data
        //     })
        // },
        addconsultCost(){
            this.consultCostList = true
        },
        // 取消文件上传
        cancelFiles() {
            this.files = [];
            this.consultCostList = false;
        },
        //移除附件
        removeFile:function(index){
            var that = this;
            that.utils.confirm("移除这个附件？",function(){
                that.files.splice(index, 1);
            })
        },
        //附件上传
        upload_file:function(params){
            var that = this;
            var file = params.file;
            that.openLoading('正在上传请稍后')
            that.utils.upload(file,function(url){
                if (!url){
                    return false;
                }
                var obj = {
                    name:url.name,
                    url:url.url,
                    hash:url.hash
                };
                that.files.push(obj);
            })
        },
        checkValue() {
            this.form.consultCost = this.form.consultCost.replace(/[^\d]/g, ''); // 仅保留数字
            if (this.form.consultCost === '' || parseInt(this.form.consultCost) < 1) {
                this.form.consultCost = '1';
            }
        }
    }
}

</script>

<style scoped>
.cform {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    background-color: #FFFFFF;
}

.cform .top {
    text-align: center;
    position: relative;
    font-size: 20px;
    color: #444444;
    height: 65px;
    box-sizing: border-box;
    line-height: 64px;
}

.cform .main {
    padding: 0 50px;
    margin-top: 15px;
}

.block {
    font-size: 16px;
    color: #444444;
}
/deep/ .code .el-radio__label {
    font-size: 16px;
}

/deep/ .code .el-radio__inner {
    width: 18px;
    height: 18px;
}
/deep/.code .el-radio__inner::after {
    width: 6px;
    height: 6px;
}
.no-resize textarea {
    resize: none!important;
}
/deep/ .el-upload-dragger {
    border: 0px dashed #d9d9d9;
    width: 137px;
    height: 45px;
}
/deep/ .el-upload-list__item-name {
    margin-left: 30px;
}
/deep/ .el-upload-list__item {
    font-size: 18px;
}
/deep/ .dragger-file  .el-cascader .el-input{
    width: 370px !important;
}
/deep/ .upload_url .el-upload-dragger {
    width: 330px !important;
    height: 164px !important;
}
/deep/.el-textarea__inner {
    resize: none;
}
/deep/ .el-divider--horizontal {
    margin: 20px 0;
}
</style>
