<template>
    <div id="expert" style="overflow:hidden;background-color: #F0F0F0;min-width: 1500px;margin-top: -40px">
        <!-- 头部 -->
        <div  style="max-width: 100%;height: 400px;margin-bottom: 10px;margin-top: 110px;background-image:url(https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon15.png)" >
            <div style="width: 1440px;height: 300px;margin: 10px auto;">
                <div style="display: flex;justify-content: center">
                    <div style="height: 200px;width: 100%;margin: 0 6%;line-height: 270px;font-weight: bold;font-size: 40px;color: white;position: relative;text-align: center">
                        百工宝·专家连线 —— 项目咨询专家1对1
                    </div>
                </div>
                <div style="height: 56px;width: 1500px;display: flex;justify-content: center;margin-top: 50px;">
                    <div >
                        <div style="width: 100%;display: flex;color: white;height: 35px;">
                            <div  v-for="(label,index) in typeList" :key="index" >
                                <div style="width:90px;height: 100%;display: flex;justify-content: center;align-items:center;font-size: 16px;cursor: pointer;" @click="add_type(index)" :class="{ fcti: index === selectTypeIndex }">{{label.label}}</div>
                            </div>
                        </div>
                        <div style="height: 56px;width: 846px;position: relative;background-color:#c2c2c266;border-radius: 5px;" :style="selectTypeIndex === 0 ? { borderTopLeftRadius: '0px' } : {borderTopLeftRadius: '5px'}">
	                        <el-input placeholder="请填写咨询内容" v-model="input" style="margin: 8px;width: calc(100% - 16px);border: none;" >
		                        <el-button slot="append" @click="talkToExpert" icon="el-icon-search">咨询</el-button>
	                        </el-input>
                        </div>
                    </div>
                    <!--  按钮   -->
                    <div style="display: flex;justify-content: flex-start;align-items: center;margin-top: 65px">
                        <div class="hand op sc" style="cursor: pointer; display: flex; align-items: center; padding: 0 15px; height: 40px;line-height: 40px;background: #F98B15; color: #ffffff; border-radius: 5px; margin-left: 20px;" @click="addselectExpert">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/Order.png" style="width: 20px; height: 20px;">
                            <span style="margin-left: 5px;">咨询自定义话题</span>
                        </div>

<!--                                <div class="hand op sc" v-if="user.expertAuthStatus === 2" style="cursor: pointer;display: flex; align-items: center; padding: 0 15px;height: 40px;line-height: 40px;background: #031F88;color: #ffffff;border-radius:5px;margin-left: 20px;" @click="utils.a('/expert/info/' + user.uuid)">-->
<!--                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/Order.png" style="width: 20px; height: 20px;">-->
<!--                                    <span style="margin-left: 5px;">我的订单</span>-->
<!--                                </div>-->
                        <div v-if="is_login === 1">
                            <div class="hand op sc" v-if="user.expertAuthStatus === 0 && user.userType == 1" style="cursor: pointer;display: flex; align-items: center; padding: 0 15px;height: 40px;line-height: 40px;background: #FFFFFF;color: #2970FF;border-radius:5px;margin-left: 20px;" @click="expert_become">
                                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/Expert.png" style="width: 20px; height: 20px;">
                                <span style="margin-left: 5px;">成为专家</span>
                            </div>
                            <div class="hand op sc" v-if="user.expertAuthStatus === 1" style="cursor: pointer;display: flex; align-items: center; padding: 0 15px;height: 40px;line-height: 40px;background: #FFFFFF;color: #2970FF;border-radius:5px;margin-left: 20px;" @click="a('/expert/become/')">
                                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/Expert.png" style="width: 20px; height: 20px;">
                                <span style="margin-left: 5px;">等待审核</span>
                            </div>
                            <div class="hand op sc" @click="a('/expert/space/'+ 0)" v-if="user.expertAuthStatus === 2" style="cursor: pointer;display: flex; align-items: center; padding: 0 15px;height: 40px;line-height: 40px;background: #FFFFFF;color: #2970FF;border-radius:5px;margin-left: 20px;" >
                                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/Expert.png" style="width: 20px; height: 20px;">
                                <span style="margin-left: 5px;">专家空间</span>
                            </div>
                        </div>
                        <div v-if="is_login === 0">
                            <div class="hand op sc" style="cursor: pointer;display: flex; align-items: center; padding: 0 15px;height: 40px;line-height: 40px;background: #FFFFFF;color: #2970FF;border-radius:5px;margin-left: 20px;" @click="expert_become">
                                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/Expert.png" style="width: 20px; height: 20px;">
                                <span style="margin-left: 5px;">成为专家</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="width: 1500px;margin:0 auto;overflow: hidden;margin-top: 10px;min-height: 100vh;">
            <!--热门话题  -->
            <div class="column">
                <div style="display: flex">
                    <div style="width: 1180px;">
                        <div style=" display: flex;justify-content: space-between;padding: 0 30px;box-sizing: border-box;border-bottom: 2px solid #DCDFE6; height: 61px;line-height: 60px" >
                            <div class="column_label">热门话题</div>
        <!--                    <div class="column_btns">-->
        <!--                        <div v-for="(item,index) in on_line_type" :key="index">-->
        <!--                            <div class="hand op sc" @click="add_typeList(item.typeId)" :style="{width: '64px', height: '30px', color: item.color,border: '1px solid ' + item.color,background: selectedTypeId === item.typeId ? item.background : 'transparent','color': selectedTypeId === item.typeId ? '#fff' : item.color}" style="border-radius: 5px;display: flex;align-items: center;justify-content: center;font-weight: 600;font-size: 18px;cursor: pointer;margin-left: 20px;">{{item.name}}</div>-->
        <!--                        </div>-->
        <!--                    </div>-->
                        </div>
                        <div style="padding: 10px 22px;box-sizing: border-box;display: flex;justify-content: space-between;align-items: center;flex-wrap: wrap;width: 1180px;min-height: 230px;">
                            <div style="overflow:hidden;width:100%;margin:0px 2px;">
                                <div v-if="hotTopicList.length > 0" >
                                    <div v-for="(item, index) in hotTopicList" :key="index"  @click="$router.push('/expert/space/' + item.uuid)">
                                        <div v-if="item" style="min-height: 20px;line-height: 20px; color: #FFFFFF; float: left;text-align: center;margin:5px;word-wrap:break-word;padding: 6px 10px; border-radius:5px;"
                                             class="hand op sc" :style="{background:color(index+1)}">{{item.title}}
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                     <el-empty style="padding: 0px 0px;" description="暂无数据"></el-empty>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 320px;">
                        <div style="border-left: 2px solid #DCDFE6;height: 100%;float: left;font-weight: 600"></div>
                        <div style="width: 128px;height: 34px;padding-top: 10px;margin: 0 auto;">
                            <el-button class="hand op" style="border-radius: 40px;;background: #2970FF;color: #FFFFFF;font-size: 16px;height: 34px;padding: 8px 20px;" @click="selectatopic">选择话题分类</el-button>
                        </div>
                        <div style="max-height: 230px; overflow-y: auto;margin-left: 10px;margin-top: 15px;">
                            <div v-for="(item,index) in recommendtype" :key="index" style="padding-top: 10px;float: left;margin-left: 2px;">
                                <el-tag style="background: #FFFFFF;font-weight: 600">{{item.name}}</el-tag>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 热门专家 -->
            <div class="column" style="margin-top: 10px;">
                <div class="column_head">
                    <div class="column_label">热门专家</div>
                    <div class="column_btns">
                        <el-cascader v-model="topicList2" size="small" placeholder="专家话题领域" :options="typeList" @change="expertChange"></el-cascader>
<!--                        <AddressSelect :address ="city"  :lv="3" :info="false" :multiple="false" class="inputplace" ></AddressSelect>-->
                        <div class="fresh hand op" style="width: 98px;height: 33px;line-height: 33px;border-radius: 5px;border: 1px solid #2970FF;text-align: center;color: #2970FF;font-size: 14px;" @click="expertChange"><i class="el-icon-search"/> 筛选专家</div>
                    </div>
                </div>
                <div style="padding:20px 28px 10px;display: flex;align-items: center;flex-wrap: wrap;">
                    <div style="width: 280px;height: 350px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);border-radius: 5px;margin-bottom: 10px;padding: 20px 20px 0;box-sizing: border-box;margin-left:5px" v-for="item in expertList" :key="item.id">
                        <div style="overflow: hidden">
                            <div style="float: left;width: 60px;height: 60px;" class="hand op" @click="utils.a('/expert/space/'+ item.uuid )">
                                <img style="width: 45px;height: 45px;border-radius: 50%;margin-top: 7px;margin-left: 7px;"  :src="item.headIco +'-200x200'">
                            </div>
                            <div style="float: left;text-overflow: ellipsis; white-space: nowrap; overflow: hidden;width: 180px;height: 60px;" @click="utils.a('/expert/space/'+ item.uuid )">
                                <div style="font-weight: bold;margin-top: 10px">
                                    <div style="vertical-align: top; line-height: 20px;cursor: pointer;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;max-width: 80px;float: left">{{item.nickName}}</div>
                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertSpace/major.png" style="width: 48px; height: 20px; margin-left: 2px;float: left">
                                    <img src="./imgs/expertIcon.png" style="width: 48px; height: 20px; margin-left: 2px;float: left">
                                </div>
                                <div style="height: 22px;font-size: 12px; line-height: 20px; color: #c1c1c1;clear: both;overflow: hidden" v-if="item.address!=={} && item.address.cityName!=null">{{item.address.cityName[0]}} | {{item.address.cityName[1]}}</div>
                            </div>
                        </div>
                        <div style="color: #999999; text-overflow:ellipsis;-webkit-line-clamp:4;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;height: 64px;" class="hand op" @click="utils.a('/expert/space/'+ item.uuid )">
                            专家简介：{{item.content}}
                        </div>
                        <el-divider></el-divider>
                        <div style="height: 115px;">
                            <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 10px;">
                                <div style="margin-left: 6px;color: #666666; width: 210px">
                                    <div class="hand op" @click="utils.a('/expert/space/'+ item.uuid )" style="line-height: 30px;text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
                                         v-for="(it,ind) in item.titleInfo.slice(0, 4)" :key="ind">社会头衔：{{ it.title }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: center; margin-top: 10px">
                            <div class="hand op" @click="collect(item.uuid)" style="text-align: center; width: 50px; font-size: 14px; padding: 2px 10px; border: 1px #2970FF solid; color: #2970FF; border-radius: 20px">关注</div>
                        </div>

                    </div>
                </div>
                <div style="padding-bottom: 20px;text-align: center;">
                    <el-pagination
                            background
                            @current-change="getExpertList"
                            :current-page.sync="hotPageNo"
                            :page-size="10"
                            layout="prev, pager, next"
                            :total="hotTotal">
                    </el-pagination>
                </div>
            </div>

            <!-- 我的专家 -->
            <div v-if="is_login === 1" class="column" style="margin-top: 10px;">
                <div class="column_head">
                    <div class="column_label">我关注的专家</div>
                    <div class="column_btns">
                        <el-cascader v-model="topicList3" size="small" placeholder="专家话题领域" :options="typeList" @change="myExpertChange"></el-cascader>
<!--                        <AddressSelect :address ="city_code"  :lv="3" :info="false" :multiple="false" class="inputplace" ></AddressSelect>-->
                        <div class="fresh hand op" style="width: 98px;height: 33px;line-height: 33px;border-radius: 5px;border: 1px solid #2970FF;text-align: center;color: #2970FF;font-size: 14px;" @click="myExpertChange"><i class="el-icon-search"/> 筛选专家</div>
                    </div>
                </div>
                <div style="padding:20px 28px 10px;display: flex;align-items: center;flex-wrap: wrap;">
                    <div style="width: 280px;height: 350px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);border-radius: 5px;margin-bottom: 10px;padding: 20px 20px 0;box-sizing: border-box;margin-left:5px" v-for="(item,index) in expertList_code" :key="index">
                        <div style="overflow: hidden">
                            <div style="float: left;width: 60px;height: 60px;" class="hand op" @click="utils.a('/expert/space/'+ item.uuid )">
                                <img style="width: 45px;height: 45px;border-radius: 50%;margin-top: 7px;margin-left: 7px;"  :src="item.headIco +'-200x200'">
                            </div>
                            <div style="float: left;text-overflow: ellipsis; white-space: nowrap; overflow: hidden;width: 180px;height: 60px;" @click="utils.a('/expert/space/'+ item.uuid )">
                                <div style="font-weight: bold;margin-top: 10px">
                                    <div style="vertical-align: top; line-height: 20px;cursor: pointer;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;max-width: 80px;float: left">{{item.nickName}}</div>
                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertSpace/major.png" style="width: 48px; height: 20px; margin-left: 2px;float: left">
                                    <img src="./imgs/expertIcon.png" style="width: 48px; height: 20px; margin-left: 2px;float: left">
                                </div>
                                <div style="height: 22px;font-size: 12px; line-height: 20px; color: #c1c1c1;clear: both;overflow: hidden" v-if="item.address!=={} && item.address.cityName!=null">{{item.address.cityName[0]}} | {{item.address.cityName[1]}}</div>
                            </div>
                        </div>
                        <div style="color: #999999; text-overflow:ellipsis;-webkit-line-clamp:4;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;height: 64px" class="hand op" @click="utils.a('/expert/space/'+ item.uuid )">
                            专家简介：{{item.content}}
                        </div>
                        <el-divider></el-divider>
                        <div style="height: 115px;">
                            <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 10px;">
                                <div style="margin-left: 6px;color: #666666; width: 210px">
                                    <div class="hand op" style="line-height: 30px;text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
                                         v-for="(it,ind) in item.titleInfo.slice(0, 4)" :key="ind">社会头衔：{{ it.title }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: center; margin-top: 10px">
                            <div class="hand op" @click="collect(item.uuid)" style="width: 50px; text-align: center; font-size: 14px; padding: 2px 10px; border: 1px #2970FF solid; color: #2970FF; border-radius: 20px">取关</div>
                        </div>
                    </div>
                </div>
                <div style="padding-bottom: 20px;text-align: center;">
                    <el-pagination
                            background
                            @current-change="getUseCollectionExpert"
                            :current-page.sync="collecctPageNo"
                            :page-size="10"
                            layout="prev, pager, next"
                            :total="collecctTotal">
                    </el-pagination>
                </div>
            </div>

            <!-- 专家团队 -->
            <div class="column" style="margin-top: 10px;">
                <div class="column_head">
                    <div class="column_label">专家团队</div>
                    <div class="column_btns">
                        <el-cascader v-model="topicList4" size="small" placeholder="团队话题领域" :options="typeList" @change="teamChange" ></el-cascader>
<!--                        <AddressSelect :address ="city_bule"  :lv="3" :info="false" :multiple="false" class="inputplace" ></AddressSelect>-->
                        <div class="fresh hand op" style="width: 98px;height: 33px;line-height: 33px;border-radius: 5px;border: 1px solid #2970FF;text-align: center;color: #2970FF;font-size: 14px;"><i class="el-icon-search" @click="teamChange"/> 筛选团队</div>
                    </div>
                </div>
                <div style="padding:20px;display: flex;flex-wrap: wrap;">
                    <div class="hand op" style="width: 280px;height: 310px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);border-radius: 5px;margin-bottom: 10px;padding: 16px 20px;box-sizing: border-box;margin-left:10px" v-for="(item, index) in expertTeamList" :key="index" @click="utils.b('/expert/team/' + item.id)">
                        <img style="width: 240px;height: 110px;background: #AFAFAF;border-radius: 5px" :src="item.cover">
                        <div style="font-size: 16px; color: #444444; margin-top: 10px;text-overflow: ellipsis; white-space: nowrap; overflow: hidden"><i class="el-icon-s-home" style="margin-right: 6px;color: #031F88;font-size: 18px"></i>{{item.name}}</div>
                        <div style="font-size: 14px;color: #2970FF;margin: 8px 0; display: flex; justify-content: space-between">
                            <div>
                                <i class="el-icon-user" style="margin-right: 6px; font-size: 16px"></i>
                                团队专家人数：{{item.exprets.length}}位
                            </div>
                            <el-dropdown v-if="item.exprets.length > 0">
                                <div><i class="el-icon-caret-bottom"></i> 展开</div>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-for="(user, key) in item.exprets" :key="key">
                                        <div>
                                            <User :data="user"></User>
<!--                                            <div style="width: 20px; height: 20px; padding: 10px 1px; border: 1px #2970FF solid; color: #2970FF;">关注</div>-->
                                        </div>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div style="color: #999999;text-overflow:ellipsis;-webkit-line-clamp:4;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;">
                            团队简介：{{item.detail}}
                        </div>
                    </div>
                </div>
                <div style="padding-bottom: 20px;text-align: center;">
                    <el-pagination
                            background
                            @current-change="getExpertTeamList"
                            :current-page.sync="teamPageNo"
                            :page-size="10"
                            layout="prev, pager, next"
                            :total="teamTotal">
                    </el-pagination>
                </div>
            </div>
            <!-- 内容精选 -->
            <div class="column" style="margin: 10px 0;">
                <div class="column_head">
                    <div class="column_label">内容精选</div>
                </div>
<!--                <div style="display: flex;justify-content: flex-start;align-items: center;margin-top: 20px;">-->
<!--                    <div style="width: 10px;height: 24px;background-color: #FF9800;margin-right: 20px"></div>-->
<!--                    <div style="font-size: 18px;color: #444444;" v-if="tableData.length !== 0">话题：{{tableData[0].topicTitle}}</div>-->
<!--                </div>-->
                <div style="padding: 20px 90px 40px 30px ">
                    <el-table
                            :data="tableData"
                            style="width: 100%"
                            :row-class-name="tableRowClassName"
                            :header-cell-class-name="headerCellClassName">
                        <el-table-column
                                prop="title"
                                label="咨询标题"
                                width="400"
                                align="center">
                        </el-table-column>
                        <el-table-column
                                prop="creatTime"
                                label="订单日期"
                                width="160"
                                align="center">
                        </el-table-column>
                        <el-table-column
                                label="订单状态"
                                width="120"
                                align="center">
                            <template>
                                    <span style="color: #8BC34A">订单完成</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="userName"
                                label="委托方"
                                width="120"
                                align="center">
                        </el-table-column>
                        <el-table-column
                                prop="expertName"
                                label="专家"
                                width="120"
                                align="center">
                        </el-table-column>
                        <el-table-column
                                prop="name"
                                label="专家响应率"
                                width="120"
                                align="center">
                        </el-table-column>
                        <el-table-column
                                prop="name"
                                label="委托方评价"
                                width="180"
                                align="center">
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                width="160"
                                align="center">
                            <template slot-scope="scope">
                                <el-button @click="handleClick(scope.row.orderNo)" type="text" size="small">
                                    查看详情
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                  <div style="padding-top: 20px;text-align: center;">
                    <el-pagination
                        background
                        @current-change="getMyExpertOrderList"
                        :current-page.sync="selectPageNo"
                        :page-size="10"
                        layout="prev, pager, next"
                        :total="selectTotal">
                    </el-pagination>
                  </div>
                </div>

            </div>
            <el-dialog clas="block" :visible.sync="selectExpert" :close-on-click-modal="false" width="1200px">
                <ustomOrder></ustomOrder>
            </el-dialog>
            <el-dialog :visible.sync="topicclassification" :close-on-click-modal="false" width="766px" class="popupwindow">
                <div class="inner-container" style="height: 630px; width: 760px; border: 1px #CED0D4 solid;margin-top: 20px;">
                    <div class="sub_types">
                        <div v-for="(type,index) in typeList" :key="index">
                            <div class="type_name"
                                 style="font-weight: 600; padding:10px 20px; background-color: #F5F7FA; font-size: 16px; display: flex; justify-content: space-between; border-bottom: 1px #CED0D4 solid">
                                <div>{{ type.label }}</div>
                                <div style="font-size: 13px; font-weight: 400">当前行业有<span style="color:#FF0000">{{type.num}}个</span>选项
                                </div>
                            </div>
                            <div style="margin: 12px 20px" v-for="(sub,subindex) in type.children" :key="subindex">
                                <div style="color: #2970FF; font-weight: 600">{{sub.label}}</div>
                                <div style="overflow:auto;">
                                    <div v-for="(tag,tagIndex) in sub.children" :key="tagIndex">
                                        <div class="sub_type hand op sc" :class="{'active':tag.check}" @click="set_type_id(tag.value,tag.label,index,subindex,tagIndex)">
                                            {{ tag.label }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display: flex;justify-content: center;padding: 10px;">
                    <div @click="empty" class="hand op sc" style="margin-right: 10px;">
                        <button style="border:none;margin-top: 10px;cursor: pointer">清除全部选择</button>
                    </div>
                    <div @click="addgetHotExpertTopic" class="hand op sc">
                        <el-button type="primary" size="medium" style="font-weight: 600">确认添加</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import User from "@/components/common/User.vue";
    import ustomOrder from "./components/ustomOrder";
    import AddressSelect from "../../components/common/city/AddressSelect";
    import Template from "@/pages/room/template.vue";

    export default {
        name: "",
        components: {
	        Template,
            User,
            ustomOrder,
            AddressSelect
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login() {
                return this.$store.state.is_login
            }
        },

        data() {
            var durationRange = (rule, value, callback) => {
                if (value < 1) {
                    callback(new Error('最少咨询1小时'));
                } else if (value > 8) {
                    callback(new Error('最多咨询8小时'));
                } else {
                    callback()
                }
            };
            return {
                topiclistcode:[
                    {index:'0',name:'#国土空间规划/生态修复一站式技术服务'},
                    {index:'1',name:'#城乡生活垃圾处理设施专项规划咨询'},
                    {index:'2',name:'#区域性产业分析和战略研究'},
                ],
                consultLoading:false,
                //热门话题分类
                topicId1:"",
                //热门专家的话提
                topicList2:[],
                //热门我关注的专家的话提
                topicList3:[],
                //热门团队的话题
                topicList4:[],
                collecctPageNo:1,
                collecctTotal:0,
                hotPageNo:1,
                hotTotal:0,
                teamTotal:0,
                teamPageNo:1,
                selectPageNo:1,
                selectTotal:0,
                page: 1,
                pageSize: 30,
                hotTopicList: [],

                expertTeamList: [],
                input: "",
                expertList: [],
                myExpertList: [],
                selectExpert: false,
                selectList: [],
                selectExpertList: [],
                form: {
                    expert: "",
                    expertTopic: "",
                    title: '',
                    content: '',
                    type: 1,
                    topicTitle: '',
                    consultCost: 0,
                    consultTime: "",
                    consultDuration: 1,
                    remark: ''
                },
                rules: {
                    topicTitle: [
                        {required: true, message: '请输入咨询话题', trigger: 'blur'},
                        {min: 3, max: 25, message: '长度在 3 到 5 个字符', trigger: 'blur'}
                    ],
                    consultTime: [
                        {required: true, message: '请选择咨询时间', trigger: 'blur'}
                    ],
                    consultDuration: [
                        {required: true, message: '请输入时长', trigger: 'change'},
                        {validator: durationRange, trigger: 'blur'}
                    ],
                    type: [
                        {required: true, message: '请选择咨询方式', trigger: 'blur'}
                    ],
                    title: [
                        {required: true, message: '请填写咨询标题', trigger: 'blur'}
                    ],
                    content: [
                        {required: true, message: '请填写咨询内容', trigger: 'blur'}
                    ]
                },
                //咨询时间的过滤规则(不能小于今天)
                timePickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e7;//如果没有后面的-8.64e7就是不可以选择今天的
                    }
                },
                fileList: [],
                enclosure: [],

                ossUrl: "https://oss.baigongbao.com/",
                value: '',

                tableData: [],
                typeList: [],
	            selectTypeIndex:0,
                hotAddress: '',
                expertAddress: '',
                groupAddress: '',
                hotType: '',
                expertType: '',
                groupType: '',

                props: {
                    lazy: true,
                    lazyLoad: (node, resolve) => {
                        const level = node.value;
                        if (level) {
                            this.newApi.getCityList({fid: level}).then(res =>{
                                const nodes = res.data
                                    .map(item => ({
                                        value: item.value,
                                        label: item.label,
                                        leaf: item.type >= 3
                                    }));
                                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                                resolve(nodes);
                            })
                        } else {
                            this.newApi.getCityList({fid: 1}).then(res =>{
                                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                                resolve(res.data);
                            })
                        }
                    }
                },
                on_line_type:[
                    {name:'全部',typeId:'0',color:'#2970FF',background:'#2970FF'},
                    {name:'策划',typeId:'1',color:'#114A81',background:'#114A81'},
                    {name:'规划',typeId:'2',color:'#2A9C8C',background:'#2A9C8C'},
                    {name:'设计',typeId:'3',color:'#E84A16',background:'#E84A16'},
                    {name:'建设',typeId:'4',color:'#945737',background:'#945737'},
                    {name:'运营',typeId:'5',color:'#F98B15',background:'#F98B15'},
                    {name:'管理',typeId:'6',color:'#945A8D',background:'#945A8D'},
                ],
                selectedTypeId:'0',
                on_line: {
                  value: 1
                },
                expertinfo:[],
                expertList_code:[],

                city: {
                    cityId : [],
                    cityName : [],
                    address:""
                },
                city_code : {
                    cityId : [],
                    cityName : [],
                    address:""
                },
                city_bule : {
                    cityId : [],
                    cityName : [],
                    address:""
                },
                topicclassification:false,
                industry:[],
                recommendtype:[]

            }
        },
        props: {},
        created: function () {
            // const industryData = localStorage.getItem('industry');
            // if (industryData) {
            //     this.industry = JSON.parse(industryData);
            // }

        },
	    watch: {
		    is_login(val) {
			    if (val) {
				    this.getExpertList();
				    this.getUseCollectionExpert();
				    this.getHotTopicList();
				    this.getExpertTeamList();
				    this.getMyExpertOrderList();
			    }
		    }
	    },
        mounted: function () {
	        if (this.is_login) {
                this.getHotTopicList();
                // 获取我关注的列表
                this.getUseCollectionExpert();
                // 获取专家信息
                // this.getExpertInfo();
                // 获取用户的推荐分类
                this.getUserRecommendType();
	        }
	        // 未登录
            this.getMyExpertOrderList();
            this.getExpertList();
            this.getExpertTeamList();
            // 热门话题
            this.getHotTopicList();
            this.getType();

        },
        methods: {
          // 咨询所有专家
          talkToExpert() {
            if (this.input) {
              this.newApi.consultExpert({typeId: this.typeList[this.selectTypeIndex].value, msg: this.input}).then(res => {
                if (res.isSuccess === 1) {
                  this.utils.sus(res.data);
                  this.input = '';
                }
              })
            } else {
              this.utils.msg('请填写咨询内容')
            }

          },
            set_type_id: function (id, name, index, subIndex, tagIndex) {
                var type_options = this.typeList;
                if (type_options[index].children[subIndex].children[tagIndex].check == null) {
                    type_options[index].children[subIndex].children[tagIndex].check = true;
                    type_options[index].num += 1
                } else {
                    type_options[index].children[subIndex].children[tagIndex].check = !type_options[index].children[subIndex].children[tagIndex].check;
                    if (type_options[index].children[subIndex].children[tagIndex].check === true) {
                        type_options[index].num += 1
                    } else {
                        type_options[index].num -= 1
                    }
                }
                this.typeList = type_options;
                this.$set(this.typeList, index, type_options[index]);

                // 存储industry数据到localStorage
                // localStorage.setItem('industry', JSON.stringify(this.industry));
                this.check_types_ok()
            },
            check_types_ok: function () {
                var typeOptions = this.typeList;
                var industry = [];
                for (const i of typeOptions) {
                    for (const k of i.children) {
                        for (const j of k.children) {
                            if (j.check) {
                                var obj = {
                                    id: j.value,
                                    name: j.label
                                };
                                industry.push(obj);
                            }
                        }
                    }
                }
                this.industry = industry;
            },
            empty(){
                this.industry = [];

                // 清除localStorage中的industry数据
                // localStorage.removeItem('industry');

                // 将所有选中的选项的check属性重置为false，并将num属性重置为0
                var typeOptions = this.typeList;
                for (const i of typeOptions) {
                    i.num = 0;
                    for (const k of i.children) {
                        for (const j of k.children) {
                            j.check = false;
                        }
                    }
                }

                // 更新typeList
                this.typeList = typeOptions;
                this.check_types_ok();
            },
            // 确定话题选择
            addgetHotExpertTopic(){
                // 提取this.industry中的id组成新的数组
                // const topicIds = this.industry.map(item => item.id);
                this.newApi.updateUserRecommendType({
                    type:'expert',
                    filed:JSON.stringify(this.industry)
                }).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.topicclassification = false;
                        this.getUserRecommendType();
                        this.getHotTopicList()
                    }
                })
            },
            // 获取用户的推荐分类
            getUserRecommendType(){
                this.newApi.getUserRecommendType({
                    type:'expert',
                }).then(res => {
                    if (res.isSuccess === 1) {
                        this.recommendtype = res.data;
                    }
                })
            },

          expertChange(){
            this.hotPageNo = 1;
            this.getExpertList();
          },
          myExpertChange(){
              if (this.is_login == 0){
                  this.$store.commit('setShowLoginPopup', true);
                  return
              }
            this.collecctPageNo = 1;
            this.getUseCollectionExpert();
          },

          teamChange(){
            this.teamPageNo =1;
            this.getExpertTeamList();
          },
            // 获取行业分类
            getType() {
                this.newApi.getTypeList({lv: 3}).then(res => {
                    for (const item of res.data) {
                        item.num = 0
                    }
                    this.typeList = res.data;
                    for (let i = 0; i < this.typeList.length; i++) {
                        for (let k = 0; k < this.typeList[i].children.length; k++) {
                            for (let m = 0; m < this.typeList[i].children[k].children.length; m++) {
                                for (let n = 0; n < this.recommendtype.length; n++) {
                                    if (this.typeList[i].children[k].children[m].value === this.recommendtype[n].typeId) {
                                        this.typeList[i].children[k].children[m].check = true; // 设置选中状态
                                        this.typeList[i].num += 1;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                })
            },

            // 查看详情
            handleClick(orderNo) {
                this.utils.a('/expert/order/' + orderNo);
            },

            // 获取内容精选
            getMyExpertOrderList() {
                this.newApi.getExpertOrderList({page:this.selectPageNo, pageSize: 10}).then(res => {
                    this.tableData = res.data
                });
            },

            // 关注专家
            collect(id) {
                if (this.is_login == 0){
                    this.$store.commit('setShowLoginPopup', true);
                    return
                }
                if (this.user.realNameStatus == 2){
                    var params = {};
                    params.uuid = id;
                    this.newApi.collectionExpert(params).then(res => {
                        if (res.isSuccess === 1) {
                            this.utils.sus(res.data);
                            this.getExpertList();
                            this.getUseCollectionExpert();
                        }

                    })
                }else {
                    this.utils.err('请先实名再关注专家！')
                }
            },

            // 获取热门专家团队列表
            getExpertTeamList() {
                var topicId4 ="";
                if(this.topicList4.length>0){
                  topicId4 = this.topicList4[this.topicList4.length-1]
                }
                this.newApi.getExpreTeam({
                  page:this.teamPageNo,
                  topicId:topicId4
                }).then(res => {
                    this.expertTeamList = res.data.records;
                    this.teamTotal = res.data.total;
                })
            },


            // 获取热门专家话题
            getHotTopicList() {
                this.newApi.getHotExpertTopic({
                    page: this.page,
                    pageSize: this.pageSize,
                }).then(res => {
                    this.hotTopicList = res.data;
                    if (res.data.length === 30) {
                        this.page++
                    } else {
                        this.page = 1
                    }
                })
            },


            color(index) {
                var firstColumn = [1, 7, 13, 19, 25];
                var secondColumn = [2, 8, 14, 20, 26];
                var thirdColumn = [3, 9, 15, 21, 27];
                var fourthColumn = [4, 10, 16, 22, 28];
                var fifthColumn = [5, 11, 17, 23, 29];
                var sixthColumn = [6, 12, 18, 24, 30];
                if (firstColumn.indexOf(index) != -1) {
                    return '#3F9FC2'
                } else if (secondColumn.indexOf(index) != -1) {
                    return '#F08A8A'
                } else if (thirdColumn.indexOf(index) != -1) {
                    return '#9298B9'
                } else if (fourthColumn.indexOf(index) != -1) {
                    return '#FF9900'
                } else if (fifthColumn.indexOf(index) != -1) {
                    return '#9298B9'
                } else if (sixthColumn.indexOf(index) != -1) {
                    return '#FF9900'
                }
            },
            tableRowClassName() {
                return 'success-row';
            },
            headerCellClassName({columnIndex}) {
                if (columnIndex === 0) {
                    return 'headerFirstCell';
                } else if (columnIndex === 8) {
                    return 'headerLastCell';
                }
                return 'headerCell'
            },
            getExpertList() {
                var topicId2 ="";
                if(this.topicList2.length>0){
                  topicId2 = this.topicList2[this.topicList2.length-1]
                }
                this.newApi.getHotExpertList({
                  page:this.hotPageNo,
                  topic:"",
                  topicId:10,
                }).then(res => {
                    this.expertList = res.data;
                    this.hotTotal = res.data.total;
                })
            },
            getUseCollectionExpert() {
                var that = this;
                var params = {};
                // params.type = 10;
                // params.pageNo = this.collecctPageNo;
                params.pageSize = 10;
                params.page = this.collecctPageNo;
                if(this.topicList3.length>0){
                  params.topicId = this.topicList3[this.topicList3.length-1]
                }
                this.newApi.getExpertCollectionList(params).then(res => {
                    that.myExpertList = res.data.records;
                    that.collecctTotal = res.data.total;
                    that.expertList_code = res.data;
                })
            },
            handleChange() {
                if (this.form.type == 1) {
                    this.form.consultCost = this.form.consultDuration * this.topic.money;
                }
            },
            upload(params) {
                this.fileList.push(params.file)
            },
            ConsultOtherTopic() {
                if (this.myExpertList.length == 0) {
                    this.utils.err("您的专家库专家数量为0,先去关注专家把");
                    return;
                }
                this.fileList = [];
                this.form.expertTopic = 0;
                this.form.topicTitle = "";
            },
            getSelectExpert() {
                this.selectExpertList = [];
                for (let i = 0; i < this.selectList.length; i++) {
                    for (let j = 0; j < this.expertList_code.length; j++) {
                        if (this.selectList[i] == this.expertList_code[j].uuid) {
                            this.selectExpertList.push(this.expertList_code[j])
                            break;
                        }
                    }
                }
            },
            // 搜索框点击切换事件
            add_type(index){
                this.selectTypeIndex = index;
            },

            // add_typeList(typeId){
            //     this.selectedTypeId = typeId;
            //     this.getHotTopicList(typeId)
            // },
            // getExpertInfo(){
            //     this.newApi.getExpertInfo({}).then(res => {
            //         this.expertinfo = res.data.status
            //     })
            // },
            expert_become(){
                 if (this.user.authenticationStatus === 2){
                     this.utils.a("/expert/become/")
                 }else {
                     this.utils.err("请先通过专业认证")
                 }
            },
            relese(){
                this.selectExpert = false
            },
            addselectExpert(){
              var that = this;
              if (that.is_login == 0){
                    this.$store.commit('setShowLoginPopup', true);
                    return
              }
              if (that.user.realNameStatus === 2){
                  that.selectExpert = true
              }else {
                  that.utils.err('请先实名认证再咨询自定义话题')
              }
            },
            // 选择话题
            selectatopic(){
                let that = this;
                if (that.is_login == 0){
                    this.$store.commit('setShowLoginPopup', true);
                    return
                }
                that.getType();
                // this.industry = Array(this.recommendtype);
                that.topicclassification = true;
             }

        }

    }

</script>

<style scoped>

    .el-drawer {
        background-color: rgba(0, 0, 0, 0.3);
    }

    .cform {
        width: 1000px;
        overflow: hidden;
        margin: 150px auto;
        background-color: #FFFFFF;
    }

    .cform .top {
        text-align: center;
        position: relative;
        font-size: 20px;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 65px;
        box-sizing: border-box;
        line-height: 64px;
    }

    .cform .main {
        padding: 0 50px;
        margin-top: 15px;
    }

    #expert .radios .el-radio__input.is-checked .el-radio__inner {
        border-color: #ff9800;
        background: #ff9800;
    }

    #expert .radios .el-radio__label {
        padding-left: 10px;
        font-size: 18px;
        color: #ffffff;
    }

    #expert .radios .el-radio__input.is-checked + .el-radio__label {
        color: #ffffff;
    }

    #expert .radios .el-radio__inner {
        border: 1px solid #ffffff;
        background: transparent;
    }

    #expert .el-input__inner {
        border-radius: 5px 0px 0px 5px;
    }

    #expert .column {
        border-radius: 5px;
        background-color: #ffffff;
    }

    #expert .column .column_head {
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #DCDFE6;
        height: 61px;
        line-height: 60px
    }

    #expert .column .column_label {
        color: #444444;
        font-weight: 600;
        font-size: 20px;
    }

    #expert .column .column_btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    #expert .column .column_btns .fresh {
        margin-left: 30px;
        color: #666666;
        font-size: 16px;
    }

    #expert .column .column_btns .inputplace {
        width: 200px;
        margin-left: 10px;
    }

    #expert .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #FF9800;
        color: #FFF;
    }

    #expert .el-table .success-row {
        height: 56px;
        background: #F5F5F5;
    }

    #expert .el-table .headerCell {
        height: 40px;
        background-color: #2970FF !important;
        color: #ffffff;
        text-align: center;
    }

    #expert .el-table .headerFirstCell {
        border-top-left-radius: 5px;
        height: 40px;
        background-color: #2970FF !important;
        color: #ffffff;
        text-align: center;
    }

    #expert .el-table .headerLastCell {
        border-top-right-radius: 5px;
        height: 40px;
        background-color: #2970FF !important;
        color: #ffffff;
        text-align: center;
    }

    #expert .el-table__body, .el-table__footer {
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 0 10px;
    }

    #expert .el-input-number {
        position: relative;
        display: inline-block;
        width: 280px;
        line-height: 40px;
    }

    #expert .el-form-item__content {
        line-height: 40px;
        position: relative;
        font-size: 14px;
    }

    #expert .el-radio__label {
        font-size: 16px;
        color: #555555;
    }
    .fcti {
        width: 100%;
        background-color: #c2c2c266;
        border-radius: 5px 5px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 40px;
    }
    /deep/ .el-dialog__body{
        padding: 0px 0px!important;
    }
    /deep/.el-input--medium .el-input__inner {
        height: 32px !important;
    }
    /deep/ .el-dialog__header {
        padding: 5px 20px 10px !important;
    }
    /deep/ .el-input-group__append{
	    background: #1c6ca1;border: none;color: #f0f0f0;
    }
    /deep/ .el-divider--horizontal {
        margin: 15px 0;
    }
    .inner-container {
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .sub_types .sub_type {
        float: left;
        margin: 10px 16px 0 0;
        padding: 4px 10px;
        border-radius: 5px;
        border: 1px solid #BCD2FF;
    }
    .sub_type.active {
        color: #FFFFFF;
        background: #2970FF;
        border: 1px solid #2970FF;
        margin: 10px 16px 0 0;
    }
    /deep/.popupwindow .el-dialog__headerbtn {
        top: 5px;
        right: 7px;
        font-size: 25px;
        font-weight: bold;
    }
</style>
